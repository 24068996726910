/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme((
	color: (
		primary: $my-primary,
		accent: $my-accent,
		)
));

@include mat.core-theme($my-theme);